@use 'src/css/breakpoint.scss' as *;

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 16px;
  cursor: pointer;
  animation: opacity 0.2s;
  color: var(--color-dusty-gray);

  &:hover {
    opacity: 0.7;
  }
}

.modal {
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: var(--light-bg-color);
  display: block;
  overflow: auto;
  outline: none;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: auto;
  z-index: 9;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

@include maxMediaWidth(sm) {
  .overlay {
    background-color: rgba(0, 0, 0, 0.25);
  }
  .modal {
    border-radius: 10px;
  }
}
