@use 'src/css/breakpoint.scss' as *;

.wrapper {
  position: fixed;
  right: 20px;
  bottom: 72px;
  display: flex;
  justify-content: center;
  width: 53px;
  height: 53px;
  padding: 10px;
  color: var(--up-button-icon-color);
  background: var(--up-button-bg-color);
  border-radius: 8px;
  border: none;
  outline: none;
  visibility: hidden;
  cursor: pointer;
  z-index: 3;

  @include maxMediaWidth(sm) {
    width: 48px;
    height: 48px;
  }

  &.show {
    visibility: visible;
  }
}

.arrow {
  width: 30px;

  @include maxMediaWidth(sm) {
    width: 23px;
  }
}
