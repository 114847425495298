@use 'src/css/breakpoint.scss' as *;

:root {
  --calculator-max-width: 455px;
  --max-content-width: 1274px;
}

html {
  font-size: 10px; // todo: set 16px & fix all rem, em
  scroll-padding-top: 110px;

  @include maxMediaWidth(lg) {
    scroll-padding-top: 100px;
  }

  @include maxMediaWidth(sm) {
    scroll-padding-top: 90px;
  }
}

body {
  font-family: var(--font-gothamPro);
  font-weight: var(--weight-normal);
  color: var(--light-neutral-text-color);
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--main-dark-color);
}

html,
body {
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: var(--main-dark-color);
}

::-webkit-scrollbar-button {
  background-image: none;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: transparent;

  &:active {
    background-color: lighten(transparent, 5%);
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgba(#0099f7, 0.4);
  background-image: none;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background-color: lighten(rgba(#0099f7, 0.4), 5%);
  }

  &:active {
    background-color: darken(rgba(#0099f7, 0.4), 5%);
  }
}

::-webkit-resizer {
  background-image: none;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

ul,
ol {
  padding: 0;
}

a {
  color: var(--link-color);
  text-decoration: none;
  background-color: transparent;
  transition: all 0.3s;

  &:hover {
    color: var(--link-hover-color);
    text-decoration: underline;
  }
}

button,
input,
textarea {
  font-family: var(--font-gothamPro);
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  line-height: 1.25;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
  }
}
