.scrollUpButton_wrapper__dVnWa{position:fixed;right:20px;bottom:72px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:53px;height:53px;padding:10px;color:var(--up-button-icon-color);background:var(--up-button-bg-color);border-radius:8px;border:none;outline:none;visibility:hidden;cursor:pointer;z-index:3}@media(max-width: 768px){.scrollUpButton_wrapper__dVnWa{width:48px;height:48px}}.scrollUpButton_wrapper__dVnWa.scrollUpButton_show__wx13V{visibility:visible}.scrollUpButton_arrow__E68CF{width:30px}@media(max-width: 768px){.scrollUpButton_arrow__E68CF{width:23px}}
.popup_close__GbFkp{position:absolute;right:20px;top:20px;width:16px;cursor:pointer;-webkit-animation:popup_opacity__EeX2T .2s;animation:popup_opacity__EeX2T .2s;color:var(--color-dusty-gray)}.popup_close__GbFkp:hover{opacity:.7}.popup_modal__AoC4A{border-radius:8px;position:absolute;top:50%;left:50%;right:auto;bottom:auto;margin-right:-50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);background:var(--light-bg-color);display:block;overflow:auto;outline:none;backdrop-filter:blur(4px);-webkit-backdrop-filter:blur(4px)}.popup_overlay__qd2Io{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,.1);overflow:auto;z-index:9;backdrop-filter:blur(4px);-webkit-backdrop-filter:blur(4px)}@media(max-width: 768px){.popup_overlay__qd2Io{background-color:rgba(0,0,0,.25)}.popup_modal__AoC4A{border-radius:10px}}
.notification_notification__av0i5:not(.notification_show__JGBly){display:none}
@font-face {
font-family: '__gothamPro_798a00';
src: url(/_next/static/media/22c5fa01a28a54ce-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gothamPro_798a00';
src: url(/_next/static/media/826b611fa8af949f-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gothamPro_798a00';
src: url(/_next/static/media/ceab32a3d8c8fecf-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__gothamPro_Fallback_798a00';src: local("Arial");ascent-override: 64.31%;descent-override: 14.09%;line-gap-override: 5.90%;size-adjust: 113.52%
}.__className_798a00 {font-family: '__gothamPro_798a00', '__gothamPro_Fallback_798a00'
}.__variable_798a00 {--font-gothamPro: '__gothamPro_798a00', '__gothamPro_Fallback_798a00'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8d7c84a11f840916-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/419a051b363af584-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/778ce0abc5526d65-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b17f997f9ae7c671-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3064ca4f871fda7c-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ddcfaf3c507de899-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/06aa60d9335bb7b5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9798597226cbd355-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24c66e387ee021e3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_28a512';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_28a512 {font-family: '__Roboto_28a512', '__Roboto_Fallback_28a512';font-weight: 500;font-style: normal
}.__variable_28a512 {--font-roboto: '__Roboto_28a512', '__Roboto_Fallback_28a512'
}

/* Document
 * ========================================================================== */

/**
 * Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

html {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  -moz-tab-size: 4; /* 3 */
  -o-tab-size: 4;
     tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent /* 4 */;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 * 3. Show the overflow in Edge 18- and IE.
 */

hr {
  color: inherit; /* 1 */
  height: 0; /* 2 */
  overflow: visible; /* 3 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
 */

nav li::before {
  content: "\200B";
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
  -ms-overflow-style: scrollbar; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

table {
  border-collapse: collapse; /* 1 */
  border-color: inherit; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */

fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
  resize: block; /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/*
 * Remove the tapping delay in IE 10.
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden="false"][hidden] {
  display: inline;
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

:root {
  /* do not modify line bellow */
  /* palette colors start */
  --white: #ffffff;
  --black: #000000;

  --white-a10: #ffffff19;
  --white-a20: #ffffff33;
  --white-a30: #ffffff4c;

  --bg-blue: #0b1427;
  --purplish-blue: #0e1a32;
  --gentian-blue: #142d53;
  --lavender: #cee4fa;
  --lavender-a16: #cee4fa29;
  --maya-blue: #7dbeff;
  --light-azure: #a4cdfa;
  --bleu-de-france: #2f92f6;

  --bleu-de-france-a10: #2f92f619;
  --bleu-de-france-10: #10203b;
  --bleu-de-france-a20: #2f92f633;
  --bleu-de-france-a30: #2f92f64d;

  --cerulean-blue: #2c529c;
  --cerulean-blue-a90: #2c529ce5;
  --ocean-blue: #183368;
  --deep-ocean-blue: #183368f5;
  --azure-radiance: #6190ed;
  --brilliant-yellow: #fdc33c;

  --yellow-ivory: #f2c94c;
  --yellow-ivory-a10: #f2c94c19;
  --yellow-ivory-a20: #f2c94c33;

  --orange-dawn: #eb5757;
  --orange-dawn-a10: #eb575719;
  --orange-dawn-a20: #eb575733;
  --orange-dawn-20: #392231;

  --grey-umber: #333333;
  --green-confirm: #1cd26a;
  --bear-ear: #886219;

  --sea-green: #219653;
  --sea-green-a10: #21965319;

  --dim-grey: #666666;
  --ash-gray: #bdbdbd;
  --platinum-gray: #e0e0e0;
  --ivory-mist: #f2f2f2;
  --navy-blue: #18258f;
  --medium-gray: #828282;
  --mustard-yellow: #face5e;
  --light-blue: #ebf5fe;
  --sky-blue: #2f80ed;
  --orange: #f2994a;
  --slate-gray: #858b96;
  --midnight-blue: #0c172e;
  --navy: #192949;
  --dark-navy: #1a2948;
  --gray: #9d9d9d;
  --light-gray: #919191;
  /* palette colors end */
  /* do not modify line above */

  /* size units start */
  /* todo: define size units here */
  /* size units end */

  /* borderRadius start */
  /* todo: add more */
  --border-radius-round: 9999px;
  /* borderRadius start */

  /* ––– */

  /* TODO: add semantic color tokens here: */
  /* base semantic tokens start */
  --color-foreground: var(--white);
  --color-foreground-alpha-10: var(--white-a10);
  --color-foreground-alpha-20: var(--white-a20);
  --color-foreground-alpha-30: var(--white-a30);

  --color-foreground-contrast: var(--black);
  --color-foreground-contrast-alpha-10: var(--black-a10);

  --color-foreground-contrast-2: var(--grey-umber);

  --color-background-contrast: var(--white);

  --color-primary: var(--bleu-de-france);
  --color-primary-alpha-10: var(--bleu-de-france-a10);
  --color-primary-shade-10: var(--bleu-de-france-10);
  --color-primary-alpha-20: var(--bleu-de-france-a20);

  --color-primary-light: var(--lavender);
  --color-primary-light-a16: var(--lavender-a16);
  --color-primary-dimmed: var(--cerulean-blue);
  --color-primary-dimmed-a90: var(--cerulean-blue-a90);
  --color-primary-dark: var(--ocean-blue);

  --color-secondary: var(--yellow-ivory);
  --color-secondary-alpha-10: var(--yellow-ivory-a10);
  --color-secondary-alpha-20: var(--yellow-ivory-a20);

  --color-warning: var(--orange-dawn);
  --color-warning-alpha-10: var(--orange-dawn-a10);
  --color-warning-alpha-20: var(--orange-dawn-a20);
  --color-warning-shade-20: var(--orange-dawn-20);
  --color-success: var(--sea-green);
  --color-success-alpha-10: var(--sea-green-a10);
  /* base semantic tokens end */

  /* TODO: will be #fff after the redesign */
  --secondary-text-color: var(--white);
  /* TODO: will not change after redesign */
  --light-neutral-text-color: var(--dim-grey);
  /* TODO: will not change after redesign */
  /* TODO: will be #fff after the redesign */
  --main-title-color: var(--white);
  /* TODO: will be #fff after the redesign */
  --primary-title-color: var(--navy-blue);
  /* TODO: will be #fff after the redesign */
  /* TODO: will be --bleu-de-france-10=rgba(47, 146, 246, 0.10) after the redesign */
  --line-color: var(--medium-gray);
  --error-text-color: var(--orange-dawn);
  --warning-text-color: var(--orange-dawn);
  --success-text-color: var(--sea-green);
  --confirming-text-color: var(--yellow-ivory);
  --primary-button-bg-color: var(--bleu-de-france);
  --primary-button-color: var(--white);
  --primary-button-hover-color: var(--bear-ear);
  --primary-button-bg-hover-color: var(--yellow-ivory);
  --primary-button-disabled-color: var(--ash-gray);
  --primary-button-disabled-bg-color: var(--ivory-mist);
  --input-shadow: var(--ash-gray);
  --input-label: var(--dim-grey);
  /* TODO: will not change after redesign */
  --light-bg-color: var(--white);
  /* TODO: will not change after redesign */
  --border-color: var(--platinum-gray);
  --tab-title-color: var(--bleu-de-france);
  --header-color: var(--white);
  --header-link-hover-color: var(--yellow-ivory);
  --footer-link-hover-color: var(--mustard-yellow);
  --footer-link-icon-color: var(--bleu-de-france);
  --footer-title-hover-color: var(--light-azure);
  --link-color: var(--bleu-de-france);
  --link-hover-color: var(--navy-blue);
  --up-button-icon-color: var(--bleu-de-france);
  --up-button-bg-color: var(--lavender);
  --list-marker-color: var(--bleu-de-france);
  --dashboard-stat-item-bg-color: var(--gentian-blue);
  --review-item-serviceIcon-bg-color: var(--light-blue);
  --calc-swap-button-bg-color: var(--bleu-de-france);
  --arcticPopup-badge-bg-color: var(--sea-green);
  --arcticPopup-description-color: var(--dim-grey);
  --content-headerItem-active-color: var(--bleu-de-france);
  --content-headerItem-completed-color: var(--yellow-ivory);
  --indicators-color: var(--bleu-de-france);
  --indicators-waiting-color: var(--ash-gray);
  --panelCheckAmount-color: var(--bleu-de-france);
  --panelConfirmTransaction-color: var(--bleu-de-france);
  --processingContent-color: var(--bleu-de-france);
  --currencyOption-title-color: var(--dim-grey);
  --selectCurrencies-icon-color: var(--bleu-de-france);
  --popup-error-content-color: var(--bleu-de-france);
  --how-it-work-bg-color: var(--sky-blue);
  --chart-tooltip-bg-color: var(--white);
  --chart-tooltip-border-color: var(--medium-gray);
  --chart-tooltip-text-color: var(--black);
  --first-section-author-back-hover-color: var(--yellow-ivory);
  --extraFields-error-color: var(--orange-dawn);
  --feedback-pointItem-bg-color: var(--ivory-mist);
  --feedback-pointItem-yellow-bg-color: var(--yellow-ivory);
  --feedback-star-color: var(--yellow-ivory);
  --feedback-input-placeholder-color: var(--ash-gray);
  --walletsBlock-icon-bg-color: var(--orange);

  /* New final colors that will not change during the redesign */
  --main-dark-color: var(--purplish-blue);
  --text-deep-dark-color: var(--black);
  --secondary-dark-color: var(--bleu-de-france);
  --special-dark-color: var(--maya-blue);
  --secondary-bg-dark-color: var(--bg-blue);
  --light-dark-color: var(--slate-gray);
  --delimiter-line-dark-color: var(--cerulean-blue);
  --delimiter-line-secondary-dark-color: var(--purplish-blue);
  --accented-text-dark-color: var(--yellow-ivory);
  --accented-text-dark-bg-color: var(--yellow-ivory-a20);
  --accented-strong-dark-color: var(--brilliant-yellow);
  --header-secondary-text-dark-color: var(--white);
  --header-bg-dark-color: var(--ocean-blue);
  --mobileNav-bg-dark-color: var(--ocean-blue);
  --light-menu-bg-hover-dark-color: var(--lavender);
  --news-bg-dark-color: var(--midnight-blue);
  --image-bg-dark-color: var(--ocean-blue);
  --widget-block-bg-dark-color: var(--purplish-blue);
  --footer-partners-bg-dark-color: var(--bg-blue);
  --today-statistics-value-dark-color: var(--maya-blue);
  --pagination-numbers-dark-color: var(--ash-gray);
  --pagination-dots-normal-dark-color: var(--cerulean-blue);
  --pagination-dots-active-dark-color: var(--bleu-de-france);
  --pagination-dots-hover-dark-color: var(--yellow-ivory);
  --light-button-bg-dark-color: var(--sky-blue);
  --indicator-line-dark-color: var(--ocean-blue);
  --indicator-progress-bg-dark-color: var(--dark-navy);
  --timer-time-dark-color: var(--mustard-yellow);
  --arcticPopup-heading-dark-color: var(--ocean-blue);
  --arcticPopup-platform-dark-color: var(--cerulean-blue);
  --search-input-bg-dark-color: var(--ocean-blue);
  --currencies-card-bg-dark-color: var(--gentian-blue);
  --currencies-card-ticker-dark-color: var(--lavender);
  --chart-tooltip-dot-dark-color: var(--maya-blue);
  --link-dark-color: var(--maya-blue);
  --modal-bg-dark-color: var(--gentian-blue);
  --loader-primary-color: var(--sky-blue);
  --loader-secondary-color: var(--bear-ear);
  --header-menu-bg-dark-color: var(--deep-ocean-blue);
  --header-menu-bg-profile: var(--cerulean-blue);
  --header-menu-bg-profile-active: var(--bleu-de-france);
  --header-menu-bg-link-hover: var(--bleu-de-france-a20);
  --header-menu-bg-target-link: var(--yellow-ivory);
  --header-menu-color-target-link: var(--purplish-blue);
  /* todo: fixit */
  --blurred-bg-color: #2c41ffb2;
  --providers-error-color: var(--orange-dawn);
  --section-bg-dark-color: var(--gentian-blue);
  --active-breadcrumb-color: var(--maya-blue);
  --breadcrumb-hover-color: var(--mustard-yellow);
  --divider-color: var(--cerulean-blue);
  --header-nav-separator: var(--azure-radiance);
  --header-border-color: var(--white);

  /* Button colors */
  --primary-button-dark-color: var(--white);
  --primary-button-bg-dark-color: var(--bleu-de-france);
  --primary-button-hover-dark-color: var(--bear-ear);
  --primary-button-bg-hover-dark-color: var(--yellow-ivory);
  --secondary-button-dark-color: var(--bear-ear);
  --secondary-button-hover-dark-color: var(--white);
  --secondary-button-bg-dark-color: var(--yellow-ivory);
  --secondary-button-bg-hover-dark-color: var(--bleu-de-france);
  --stroke-button-dark-color: var(--yellow-ivory);
  --stroke-button-hover-dark-color: var(--bleu-de-france);
  --neutral-button-dark-color: var(--white);
  --neutral-button-bg-dark-color: var(--ocean-blue);
  --neutral-button-bg-hover-dark-color: var(--bleu-de-france);
  --neutral-dark-color: var(--gray);
  --accented-dark-color: var(--grey-umber);
  --calc-notification-bg-dark-color: var(--lavender);
  --default-currency-badge-color: var(--light-gray);
  --auth-button-color: #54a9eb;

  --bg-image-gradient: linear-gradient(
    335deg,
    rgba(61, 80, 255, 0.6) -0.06%,
    rgba(61, 80, 255, 0) 55.28%
  );
  --bg-error-page-gradient: linear-gradient(
    335deg,
    rgba(61, 80, 255, 0.6) -0.06%,
    rgba(61, 80, 255, 0) 99.28%
  );
  --bg-fade-card-gradient: linear-gradient(
    96deg,
    rgba(20, 45, 83, 0.8) 0%,
    rgba(20, 45, 83, 0) 106.1%
  );
  --bg-up-fade-card-gradient: linear-gradient(180deg, #142d53 0%, rgba(20, 45, 83, 0) 100%);
  --bg-left-fade-card-gradient: linear-gradient(
    114.13deg,
    #142d53 0.9%,
    rgba(20, 45, 83, 0) 108.81%
  );
  --market-data-border-gradient: linear-gradient(
    271.08deg,
    rgba(47, 146, 246, 0) -2.64%,
    #2f92f6 52.46%,
    rgba(47, 146, 246, 0) 101.2%
  );
  --join-now-gradient: linear-gradient(270deg, #162e53 0%, #243c6c 50.29%, #134a9d 102.12%);
  --footer-partners-gradient: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
  --skeleton-gradient: linear-gradient(
    90deg,
    rgba(125, 190, 255, 0.8) 0%,
    rgba(125, 190, 255, 0.16) 100%
  );
  --wallet-card-gradient: linear-gradient(127.98deg, #307ad2 0%, #2d89e7 100%);
  --header-menu-gradient: linear-gradient(180deg, #2f92f6 0%, rgba(47, 146, 246, 0) 100%);
  --transition-rapid: ease-in-out 0.15s;
  --transition: ease-in-out 0.3s;
  --list-marker-size: 10px;
  --loader-animation: bubble 1.3s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --skeleton-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  --weight-normal: 400;
  --weight-medium: 500;
  --weight-bold: 700;

  /* Desktop Fonts */
  --font-h1: 400 55px/65px var(--font-gothamPro);
  --font-h2: 400 45px/55px var(--font-gothamPro);
  --font-h3: 400 40px/50px var(--font-gothamPro);
  --font-h4: 700 40px/50px var(--font-gothamPro);
  --font-h5: 400 30px/40px var(--font-gothamPro);
  --font-h6: 400 24px/34px var(--font-gothamPro);
  --font-h7: 700 18px/26px var(--font-gothamPro);
  --font-h8: 400 18px/26px var(--font-gothamPro);
  --font-h9: 400 16px/24px var(--font-gothamPro);
  --font-h10: 400 16px/24px var(--font-gothamPro);
  --font-h11: 400 14px/18px var(--font-gothamPro);
  --font-h12: 400 14px/24px var(--font-gothamPro);
  --font-h13: 400 12px/16px var(--font-gothamPro);
  --font-h14: 600 20px/30px var(--font-gothamPro);
  --font-h15: 500 16px/24px var(--font-gothamPro);
  --font-blockchain: 500 12px var(--font-gothamPro);
  --font-button: 700 20px/30px var(--font-gothamPro);
  --font-button-small: 400 16px/26px var(--font-gothamPro);
  --font-button-stroke: 400 16px/24px var(--font-gothamPro);
  --font-calculator-namecoin: 500 22px/22px var(--font-gothamPro);
  --font-header-custom-link: 500 20px/30px var(--font-gothamPro);
  --font-badge: 500 10px/12px var(--font-gothamPro);
  --font-chart: 400 10px/12px var(--font-gothamPro);
  --font-page-error: 400 150px/150px var(--font-gothamPro);
  --font-large: 500 90px/90px var(--font-gothamPro);
  --font-oauth-btn: 500 16px/22px var(--font-roboto);

  /* Tablet Fonts */
  --font-h1-md: 400 36px/50px var(--font-gothamPro);
  --font-h2-md: 400 26px/36px var(--font-gothamPro);
  --font-h3-md: 400 22px/36px var(--font-gothamPro);
  --font-h4-md: 400 20px/34px var(--font-gothamPro);
  --font-h5-md: 400 18px/28px var(--font-gothamPro);
  --font-h6-md: 400 18px/24px var(--font-gothamPro);
  --font-h7-md: 400 16px/26px var(--font-gothamPro);
  --font-h8-md: 400 14px/24px var(--font-gothamPro);
  --font-h9-md: 400 16px/26px var(--font-gothamPro);
  --font-h10-md: 400 14px/24px var(--font-gothamPro);
  --font-h11-md: 400 14px/24px var(--font-gothamPro);
  --font-h12-md: 400 14px/19px var(--font-gothamPro);
  --font-h14-md: 600 16px/24px var(--font-gothamPro);
  --font-button-md-bold: 700 16px/24px var(--font-gothamPro);
  --font-headline-4: 700 20px/26px var(--font-gothamPro);

  /* Mobile Fonts */
  --font-h1-sm: 400 24px/30px var(--font-gothamPro);
  --font-h2-sm: 400 22px/30px var(--font-gothamPro);
  --font-h3-sm: 400 20px/28px var(--font-gothamPro);
  --font-h4-sm: 700 20px/24px var(--font-gothamPro);
  --font-h5-sm: 400 18px/28px var(--font-gothamPro);
  --font-h6-sm: 700 18px/26px var(--font-gothamPro);
  --font-h7-sm: 400 16px/24px var(--font-gothamPro);
  --font-h8-sm: 400 14px/24px var(--font-gothamPro);
  --font-h9-sm: 400 14px/24px var(--font-gothamPro);
  --font-h11-sm: 400 16px/24px var(--font-gothamPro);
  --font-h13-sm: 400 14px/20px var(--font-gothamPro);
  --font-h14-sm: 400 14px/18px var(--font-gothamPro);
  --font-button-big-sm-bold: 700 16px/24px var(--font-gothamPro);
  --font-ultra-sm: 400 12px/16px var(--font-gothamPro);
}

@-webkit-keyframes bubble {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.2;
  }
}

@keyframes bubble {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.2;
  }
}

@-webkit-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

:root{--calculator-max-width: 455px;--max-content-width: 1274px}html{font-size:10px;scroll-padding-top:110px}@media(max-width: 1110px){html{scroll-padding-top:100px}}@media(max-width: 768px){html{scroll-padding-top:90px}}body{font-family:var(--font-gothamPro);font-weight:var(--weight-normal);color:var(--light-neutral-text-color);-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;-moz-osx-font-smoothing:grayscale;-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:var(--main-dark-color)}html,body{height:100%;max-height:100%;width:100%;background-color:var(--main-dark-color)}::-webkit-scrollbar-button{background-image:none;background-repeat:no-repeat;width:100%;height:0}::-webkit-scrollbar-track{background-color:transparent}::-webkit-scrollbar-track:active{background-color:rgba(13,13,13,0)}::-webkit-scrollbar-thumb{border-radius:0;background-color:rgba(0,153,247,.4);background-image:none;background-repeat:no-repeat;background-position:center}::-webkit-scrollbar-thumb:hover{background-color:rgba(18,165,255,.4)}::-webkit-scrollbar-thumb:active{background-color:rgba(0,137,222,.4)}::-webkit-resizer{background-image:none;background-repeat:no-repeat;background-color:transparent;width:4px;height:4px}::-webkit-scrollbar{width:4px;height:4px}ul,ol{padding:0}a{color:var(--link-color);text-decoration:none;background-color:transparent;-webkit-transition:all .3s;transition:all .3s}a:hover{color:var(--link-hover-color);text-decoration:underline}button,input,textarea{font-family:var(--font-gothamPro)}h1,h2,h3,h4{margin:0}h1{line-height:1.25}@media(prefers-reduced-motion: reduce){*{-webkit-transition:none !important;transition:none !important;-webkit-animation:none !important;animation:none !important}}
